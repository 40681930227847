.add-btn {
  background: rgb(65, 184, 65);
  color: #fff;
  padding: 12px 10px;
  border-radius: 10px;
  border: none;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.supplier-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 100px;
  margin-top: 10px;
}

.supplier-row {
  grid-gap: 10px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-face-color: orange;
  scrollbar-shadow-color: grey;
  scrollbar-highlight-color: red;
  scrollbar-color: orange grey;
  scrollbar-width: thin;
  background: #FFFCFC;
}

@media screen and (min-width:500px) {
  .supplier-row {
    max-height: 400px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.supplier-col {
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
}

.supplier-row::-webkit-scrollbar {
  width: 5px;
}

.supplier-row::-webkit-scrollbar-track {
  /* background-color: grey; */
  box-shadow: inset 0 0 5px grey;
  border-radius: 15px;
}

.supplier-row::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 15px;
}

.supplier-box {}

.supplier-box .supplier-box-content {}

.supplier-box .supplier-box-content .supplier-box-title {}

.supplier-box .supplier-box-content .supplier-box-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-wrapper .inputBox-input,
.supplier-row .inputBox-input {
  background: #fff;
  border: 1px solid #ddd;
  transition: border .5s;
}

.input-wrapper .inputBox-input:focus,
.supplier-row .inputBox-input:focus {
  border-color: #aaa;
}

.remove-supplier {
  background-color: #D74C4C;
  padding: 6px;
  border: none;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
  font-weight: 500;
}

.product-card {
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 4px;
  margin-top: 10px;
}

.product-card .product-card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-card .product-card-body .form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.supplier-box-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}


/* Define fade-in and fade-out animations */
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.add-product {
  background-color: rgba(8, 122, 65, 0.842);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  padding: 6px;
  margin-top: 4px;
  margin-bottom: 10px;
}

/* supplier dashboard */
.dashboard {
  padding: 20px;
}

.sr-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #fff;
}

/* submission search */
.search-container {
  margin: 20px;
}

.search-container input[type="text"] {
  padding: 8px;
  margin-right: 10px;
  font-size: 16px;
}

.search-container button {
  padding: 8px 12px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.submission-details {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 100px;
}

.submission-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.submission-details h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.submission-details h4 {
  font-size: 18px;
  margin-bottom: 8px;
}

.submission-details h5 {
  font-size: 16px;
  margin-bottom: 6px;
}

.submission-details p {
  margin-bottom: 4px;
}

/* submission details */

.sellere-part {
  border: 1px solid #ddd;
  padding: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 8px;
}

.sellere-part h3,
h2,
h4 {
  color: rgb(43, 56, 80);
  line-height: 1.3;
  font-weight: 700;
}

.sellere-part p {
  color: rgb(117, 117, 117);
  font-size: 14px;
  font-weight: 400;

}

.sellere-part .search-container {
  display: flex;
  gap: 3px;
}

.sellere-part .search-container input {
  flex: 1;
  border: 1px solid #ddd;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  outline: none
}

.sellere-part .search-container button {
  border: none;
  background: #1A985C;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 500;
  padding: 0 20px;
}

.sellere-part .supplier-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

/* @media and (min-width:768px ) {
  .sellere-part .supplier-boxes {
    flex-direction: row;
  }

} */

.sellere-part .supplier-box {
  padding: 0px;
  border: 2px dashed #ccc;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sellere-part .products {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.sellere-part .products .product {
  border-radius: 4px;


}

.sellere-part .editBtn {
  border: none;
  background: #1A985C;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 500;
  padding: 15px 30px;
  display: block;
  text-align: center;
  margin-bottom: 50px;
  margin: auto;
}

table.product {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

table.product th {
  padding: 8px 3px;
  background: #6C6C6C;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
  border: 1px solid #ddd;
}

table.product td {
  border: 1px solid #ddd;
  padding: 8px 3px;
  font-size: 14px;
}

.sellere-part .supplierTitle {
  font-size: 20px;
  line-height: 1.9;
}


.srDashboard .total-container {
  display: flex;
  gap: 10px;
}

.srDashboard .total-container .total-box {
  border: 1px solid #ddd;
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.srDashboard .total-container .total-box h3 {
  font-weight: 900;
  color: #2b3850;
  font-size: 20px;
}

.srDashboard .total-container .total-box p {
  color: #757575;
  font-weight: 500;
}

.recent-supplies,
.recent-salses {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 50px;
}

.recent-supplies h3,
.recent-salses h3 {
  font-weight: 900;
  color: #2b3850;
  font-size: 20px;
}

/* list styling  */
.recentList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.recentList li {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;
  padding: 6px;
  border-radius: 6px;
}

.recentList li .left {
  display: flex;
  flex-direction: column;
}

.recentList li .left .id {
  color: #2b3850;
  font-size: 14px;
}

.recentList li .left .date {
  color: #757575;
  font-size: 12px;
}

.recentList li .right {
  cursor: pointer;
}

.remove-product {
  border: none;
  padding: 6px;
  background-color: #D74C4C;
  color: #fff;
  border-radius: 4px;

}

.btn-loader {
  border: 3px solid #fff;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 28px;
  height: 28px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.coming-soon-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.company-logo {
  max-width: 150px;
  margin-bottom: 20px;
}



.coming-soon-container h1 {
  font-size: 2rem;
  color: #333;
}

.coming-soon-container p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 40px;
}

.dashboardinputBox {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.krishijotno-admin {
  padding: 30px;
}

.krishijonto-search {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  margin: 10px 0;
}

.krishijonto-search input {
  padding: 10px;
  flex: 1;
}

.krishijonto-search button {
  background-color: #1A985C;
  color: #fff;
  border: none;
  padding: 10px 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
}

/* order v2 page  */

.v2-wrapper {
  padding: 0 30px;
}

.v-2-filter {
  position: sticky;
  top: 0;
  left: 0;
}

.timestamps {
  margin-top: 20px;
  font-size: 10px;
  position: relative;
}

.timestamp-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  position: absolute;
  height: 100%;
  border-left: 2px solid black;
  /* Adjust the color and thickness as needed */
  left: 10px;
  /* Adjust the position of the line */
}

.bullet {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 16px;
  margin-left: -4px;
  /* Adjust the position of the bullet */
  color: crimson;
  font-size: 20px;
}

.status {
  font-weight: bold;
  margin-left: 20px;
  /* Adjust the distance between the bullet and the status */
}

.time {
  font-style: italic;
  margin-left: 10px;
  /* Adjust the distance between the status and the time */
}

.signle-line {
  position: absolute;
  height: 100%;
  width: 3px;
  background-color: #ddd;
}

.statementInfo img {
  max-width: 100%;
  height: auto;
  display: block;
}
.statementInfo p {
  font-family: Inter;
  color: rgb(17, 25, 39);
  font-size: 12px;

}